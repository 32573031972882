* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}

html {
    scroll-behavior: smooth;

}

body {
    background-color: whitesmoke;

    #root {
        #topMenu {
            color: white;
            background-color: #02325D;
            height: 60px;
            padding: 0 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: fixed;
            z-index: 2000;
            top: 0;
            right: 0;
            left: 0;

            .menuBar {

                i {
                    font-size: 20px;
                    cursor: pointer;
                    transition: all .3s;

                    &:hover {
                        color: orangered;

                    }

                }
            }

            h2 {
                a {
                    color: white;
                    text-decoration: none;
                    transition: all .3s;

                    &:hover {
                        color: orangered;

                    }


                }
            }

        }

        .megaMenu {
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: orangered;
            z-index: 3000;
            overflow: hidden;
            transition: all .3s;

            ul {
                padding: 30px;

                li {
                    list-style: none;
                    padding: 10px 0;




                    a {
                        color: white;
                        text-decoration: none;
                        font-size: 20px;
                        transition: all .3s;

                        &:hover {
                            color: black;
                            font-weight: bold;
                            font-size: 22px;

                        }



                    }
                }
            }

        }

        #container {
            margin-top: 60px;

            // background-color: red;
            .banner {
                // background-image: url('.././public/img/banner_tp.jpg');
                // background-repeat: no-repeat;
                // background-size: cover;
                // filter: grayscale(1);
                // width: 100%;
                // height: 600px;

            }

            .content {

                &:nth-child(odd) {

                    background-color: rgb(238, 238, 238);
                }

                .contentItem {
                    // background-color: aquamarine;
                    width: 1100px;
                    margin: 0px auto;
                    padding: 30px 0;



                    h1 {
                        color: #02325D;
                    }

                    ul {
                        margin: 30px 0 30px 30px;

                        li {
                            padding: 5px;
                            line-height: 30px;
                            text-align: justify;
                            color: #02325D;


                            b {
                                color: orangered;

                            }

                        }
                    }

                    p {
                        margin: 10px 0;
                        line-height: 30px;
                        text-align: justify;
                        color: #02325D;

                    }

                    form {
                        position: relative;
                        margin-top: 20px;

                        .inputItem {
                            position: relative;
                            margin-bottom: 5px;
                            z-index: 100;
                            // border-bottom: 1px solid silver;

                            input {
                                width: 100%;
                                padding: 0 5px 0 30px;
                                height: 36px;
                                font-size: 16px;
                                // border: none;
                                // outline: none;
                                // background-color: transparent;
                            }

                            i {
                                position: absolute;
                                top: 10px;
                                left: 8px;
                                color: silver;
                            }

                            textarea {
                                width: 100%;
                                height: 100px;
                                padding: 8px;
                                font-size: 16px;
                                resize: none;
                            }
                        }

                        button {
                            background-color: orangered;
                            color: white;
                            border: none;
                            padding: 10px 20px;
                            font-size: 18px;
                            font-weight: 500;
                            width: 100%;
                            cursor: pointer;
                            transition: all .3s;
                            border-radius: 5px;
                            margin: 0 auto;

                            &:hover {
                                background-color: red;
                            }
                        }
                    }

                    iframe {
                        border: none;
                        margin: 20px auto;
                        width: 100%;
                        height: 600px;
                    }


                    //home page
                    .contentItemWrap {
                        display: flex;
                        gap: 20px;
                        flex-wrap: wrap;
                        // justify-content: space-between;
                        margin-top: 30px;

                        .productItemWrap {
                            padding: 10px;
                            width: calc((1100px - 60px)/4);
                            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                            cursor: pointer;
                            transition: all .5s;
                            background-color: orangered;
                            border-radius: 5px;


                            &:hover {
                                background-color: red;

                                .productItem {
                                    h2 {
                                        color: white;
                                        scale: 1.1;
                                    }
                                }

                            }

                            .productItem {
                                padding: 30px;
                                // border: 1px solid silver;
                                border-radius: 5px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                min-height: 300px;

                                h2 {
                                    text-transform: capitalize;
                                    color: white;
                                    text-align: center;
                                    transition: all .5s;

                                }

                            }
                        }


                    }

                    //event
                    .eventItem {
                        margin: 20px auto;
                        padding: 10px;
                        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                        cursor: pointer;
                        background-color: white;
                        border-radius: 8px;
                        transition: all .5s;


                        &:hover {
                            background-color: whitesmoke;

                        }

                        span {
                            color: silver;
                        }

                        h2 {
                            color: orangered;
                            margin-top: 5px;

                        }
                    }
                }

            }
        }

        footer {
            .content {
                display: flex;
                justify-content: space-between;
                background-color: #02325D;
                // background-image: url('.././public/img/footer.jpg');
                // background-size: cover;
                // background-repeat: no-repeat;
                // height: 300px;


                .menu {
                    padding: 30px;

                    ul {
                        li {
                            // list-style: none;
                            padding: 5px;
                            color: white;
                            font-size: 18px;


                            a {
                                text-decoration: none;
                                color: white;

                                // color: orangered;
                            }
                        }
                    }
                }

                .address {
                    padding: 30px;

                    ul {
                        li {
                            list-style: none;
                            padding: 5px;
                            color: white;


                            i {
                                width: 20px;
                                text-align: center;
                                margin-right: 5px;
                                color: orangered;
                                color: white;

                            }

                            a {
                                text-decoration: none;
                                color: black;
                                transition: all .3s;
                                color: white;


                                &:hover {
                                    color: orangered;


                                }
                            }

                        }
                    }
                }
            }

            p {
                text-align: center;
                background-color: #02325D;
                color: silver;
                padding: 10px;
                font-size: 14px;
            }
        }



    }
}

@media screen and (max-width: 687px) {
    body {
        #root {
            #container {
                .banner {
                    // height: 280px;
                    // background-size: contain;
                }

                .content {
                    .contentItem {
                        width: 100%;
                        padding: 30px 10px;


                        .contentItemWrap {
                            justify-content: center;

                            .productItemWrap {
                                width: 100%;


                                .productItem {}
                            }

                        }
                    }
                }
            }

            footer {
                .content {
                    padding: 30px 0;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    gap: 30px;

                    .menu {
                        padding: 10px;

                        ul {
                            margin-left: 20px;

                            li {
                                font-size: 18px;
                            }
                        }


                    }

                    .address {
                        padding: 10px;



                    }
                }


            }

        }
    }

}